import {
  learnMoreTemplate,
  getValueAddHTML
} from "../pages/company/bots/templates/learn-more.template";
import { API, graphqlOperation } from "aws-amplify";

const AWS = require("aws-sdk");

const queries = require("../graphql/queries");

const q = require("q");
const _ = require("lodash");

AWS.config.apiVersions = {
  s3: "2006-03-01"
};

const config = new AWS.Config({
  accessKeyId: "AKIAWJ7IHLCOAZ454343",
  secretAccessKey: "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",
  region: "us-east-2"
});
var s3 = new AWS.S3(config);
function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const publishBot = async (company) => {
  const bots = company.bots.items.map((i) => {
    if (i.learnMore && IsJsonString(i.learnMore)) {
      i.learnMore = JSON.parse(i.learnMore);
    }

    if (!i.learnMore) {
      i.learnMore = [
        {
          id: "99999",
          name: "learn-more-insert",
          steps: [],
          probing_questions: []
        }
      ];
    }

    let branding = {};
    if (company.branding) {
      branding = company.branding;
    }

    let probing = [];
    if (i.learnMore) {
      probing = [...i.learnMore[0].probing_questions].filter(
        (z) => z.id !== "learn-more"
      );
    }

    probing.push({
      id: "learn-more",
      component: {
        type: "html",
        html: getValueAddHTML(company.branding)
      },
      asMessage: false,
      trigger: "company-learn-more-description"
    });
    i.learnMore[0].probing_questions = probing;

    i.objections = IsJsonString(i.objections)
      ? JSON.parse(i.objections)
      : i.objections;
    i.serviceQuestions = IsJsonString(i.serviceQuestions)
      ? JSON.parse(i.serviceQuestions)
      : i.serviceQuestions;
    return i;
  });

  await Promise.all(
    company.programs.items.map(async (program) => {
      const payload = {
        id: program.id,
        version: "v0"
      };
      try {
        const getServices = await API.graphql(
          graphqlOperation(queries.getProgramVersioned, payload)
        );
        program.services = getServices.data.getProgramVersioned.services;
      } catch (e) {
        console.log(e);
      }

      return program;
    })
  );

  await Promise.all(
    company.priceTables.items.map(async (priceTable) => {
      const payload = {
        id: priceTable.id,
        version: "v0"
      };
      try {
        const getPricetTable = await API.graphql(
          graphqlOperation(queries.getPriceTableVersioned, payload)
        );
        priceTable.rows = getPricetTable.data.getPriceTableVersioned.rows;
      } catch (e) {
        console.log(e);
      }

      return priceTable;
    })
  );

  company.bots.items = bots;

  /*  Consolidate the probing questions */
  const services = _.uniq(company.programs.items.map((i) => i.programType));

  services.forEach((s) => {
    const pp = company.programs.items.filter((p) => p.programType === s);
    if (pp.length > 1) {
      const selected = pp.find((i) => i.useProbingQuestions);
      if (selected) {
        pp.filter((i) => !i.useProbingQuestions).forEach((t) => {
          t.probingQuestionsJson = selected.probingQuestionsJson;
        });
      }
    }
  });

  const q = {
    Bucket: "lawnbot-client-data",
    Key: `${company.id}/company.json`,
    ContentType: "application/json",
    Body: new Buffer(JSON.stringify(company, null, 2), "utf-8"),
    ContentEncoding: "utf8"
  };

  s3.putObject(q, function (err, data) {
    alert("published");
  });
};

// module.exports.publishBot = publishBots;

// const getCompanyBotData = (companyId, botId) => {

//     return new Promise((res, rej) => {

//         const companyPromise = s3.getObject(
//             {
//                 Bucket: "lawnbot-client-data",
//                 Key: `${companyId}/company.json`
//             }).promise();

//             const botPromise = s3.getObject(
//             {
//                 Bucket: "lawnbot-client-data",
//                 Key: `${companyId}/bots/${botId}/learnmore.json`
//             }).promise();

//             const botPromise2 = s3.getObject(
//                 {
//                     Bucket: "lawnbot-client-data",
//                     Key: `${companyId}/bots/${botId}/objections.json`
//                 }).promise();

//             Promise.all([companyPromise, botPromise, botPromise2]).then((info, data) => {
//                 let totalJson = {}
//                 const companyJson = parseJsonResponse(info[0]);
//                 const botJson = parseJsonResponse(info[1]);
//                 const botJson2 = parseJsonResponse(info[2]);
//                 totalJson = Object.assign({}, companyJson, botJson, botJson2);
//                 res(totalJson);

//             }).catch((e) => {
//                 rej(e);
//             });
//         }

//         );

//     }

//     const parseJsonResponse = (response) => {

//             try {
//                 return json = JSON.parse(response.Body.toString('utf8'));

//             }
//                 catch (e) {
//                     return {error: e.message};
//                 }

//     }

//     module.exports.getCompanyBotData = getCompanyBotData;
