import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  mainListItems,
  secondaryListItems,
  customerListItems
} from "./listItems";
import Chart from "./Chart";
import Deposits from "./Deposits";

import * as _ from "lodash";

import { Switch, Route, useLocation } from "react-router-dom";
import CompanyPage from "../company/company-page";
import { CompanyDetail } from "../company/company-detail";
import { CompanyBranding } from "../company/company-branding";
import { CompanyDataServices } from "../company/company-data-services";

import { API, graphqlOperation } from "aws-amplify";
import { listCompanyVersioneds } from "./../../graphql/queries";

import CompanyListTable from "../company/company-list-table";
import { CompanyNew } from "../company/company-new";
import CompanyBotsPage from "../company/bots/company-bots-page";
import { CompanyBotNew } from "../company/bots/bot-new";
import { CompanyBotDetail } from "../company/bots/bot-detail";
import CompanyProgramPage from "../company/programs/company-programs-page";
import { CompanyProgramNew } from "../company/programs/program-new";
import { CompanyProgramDetail } from "../company/programs/program-detail";
import CompanyPriceTablePage from "../company/price-tables/company-price-table-page";
import { CompanyPriceTableNew } from "../company/price-tables/company-price-table-new";
import { CompanyPriceTableDetail } from "../company/price-tables/price-table-detail";
import ProgramProbingQuestionsPage from "../company/programs/probing-questions/company-probing-questions";
import { CompanyBotLearnMore } from "../company/bots/bot-learn-more";
import { CompanyBotObjections } from "../company/bots/bot-objections";
import { CompanyBotServiceQuestions } from "../company/bots/bot-service-questions";

import { RealGreenIntegration } from "../company/intergrations/real-green-page";
import { PestPacPage } from "../company/intergrations/PestPacPage.jsx";
import { Button, Select, ListItem } from "@material-ui/core";

import CompanyOffersPage from "../company/offers/company-offers-page";
import { CompanyOfferNew } from "../company/offers/offer-new";
import { CompanyOfferDetail } from "../company/offers/offer-detail";
import { LawnbotDashboardIntegration } from "../company/intergrations/lawnbot-dashboard";
import { CompanyBotPreModal } from "../company/bots/bot-pre-modal";

import { createTitle } from "../../utils/createTitle";
import { CloningModal } from "./cloning/CloningModal.jsx";
import { GoogleSheetIntegration } from "../company/intergrations/google-sheets-page";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        LawnBot
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openCloningModal, setOpenCloningModal] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [companies, setCompanies] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [currentCName, setCurrentCName] = useState("");

  const { pathname: path } = useLocation();

  const hasActiveCompany = /company\/[A-Za-z0-9-]+$/.test(path);

  useEffect(() => {
    const fetchCompaniesPage = (page) => {
      return new Promise((res, rej) => {
        let list = [];
        try {
          API.graphql(
            graphqlOperation(listCompanyVersioneds, {
              filter: { version: { eq: "v0" } },
              limit: 1000,
              nextToken: page
            })
          ).then(async (c) => {
            const stuff =
              c.data.listCompanyVersioneds && c.data.listCompanyVersioneds.items
                ? _.sortBy(c.data.listCompanyVersioneds.items, ["companyName"])
                : [];
            list = [...stuff];

            if (
              c.data.listCompanyVersioneds &&
              c.data.listCompanyVersioneds.nextToken
            ) {
              try {
                const s = await fetchCompaniesPage(
                  c.data.listCompanyVersioneds.nextToken
                );
                list = [...list, ...s];
              } catch (e) {
                console.log(e);
              }
            }
            res(list);
          });
        } catch (e) {
          console.log(e);
          res([]);
        }
      });
    };
    const fetchCompanies = async () => {
      try {
        const fetch = await API.graphql(
          graphqlOperation(listCompanyVersioneds, {
            filter: { version: { eq: "v0" } },
            limit: 10000
          })
        );

        let c = fetch.data.listCompanyVersioneds
          ? _.sortBy(fetch.data.listCompanyVersioneds.items, ["companyName"])
          : [];
        if (fetch.data.listCompanyVersioneds.nextToken) {
          let more = await fetchCompaniesPage(
            fetch.data.listCompanyVersioneds.nextToken
          );
          c = [...c, ...more];
        }

        setCompanies(c);
        setLoaded(true);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchCompanies();
    // subscribeToCompanies();
  }, []);

  useEffect(() => {
    if (hasActiveCompany) {
      const matches = path.match(/company\/([A-Za-z0-9-]+)$/);
      if (matches.length) {
        const cid = matches[1];
        const cc = companies.find((i) => i.id === cid);
        if (cc && cc.bots && cc.bots.items) {
          setBots(cc.bots.items);
          if (cc.bots.items.length) {
            // setSelectedBot(cc.bots.items[0]);
          }
        }
      }
    }
  }, [hasActiveCompany, path, companies]);

  return loaded ? (
    <div className={classes.root}>
      <CloningModal
        handleClose={() => setOpenCloningModal(false)}
        open={openCloningModal}
        companyId={window.location.pathname.split("/")[2]}
      />
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {createTitle(currentCName, path)}
          </Typography>
          {hasActiveCompany ? (
            <>
              <Button
                color="secondary"
                style={{ backgroundColor: "white" }}
                onClick={() => {
                  setOpenCloningModal(true);
                }}
              >
                Clone Company
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Switch>
            <Route
              path="/company/:companyId"
              render={(match) => {
                let { companyId } = match.match.params;
                let company = companies.find((i) => i.id === companyId);
                if (typeof company === "undefined") {
                  company = { id: companyId };
                }
                setCurrentCName(company.companyName);
                return customerListItems(company);
              }}
            ></Route>
            <Route path="/">{mainListItems}</Route>
          </Switch>
        </List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path="/company/new">
            <CompanyNew />
          </Route>
          <Route exact path="/company/:companyId/program/new">
            <CompanyProgramNew />
          </Route>
          <Route
            exact
            path="/company/:companyId/program/:programId/probing-questions"
          >
            <ProgramProbingQuestionsPage />
          </Route>
          <Route exact path="/company/:companyId/program/:programId">
            <CompanyProgramDetail />
          </Route>
          <Route exact path="/company/:companyId/bot/new">
            <CompanyBotNew />
          </Route>
          <Route exact path="/company/:companyId/price-table/new">
            <CompanyPriceTableNew />
          </Route>
          <Route exact path="/company/:companyId/price-table/:priceTableId">
            <CompanyPriceTableDetail />
          </Route>
          <Route path="/company/:companyId/bot/:botId/learn-more">
            <CompanyBotLearnMore />
          </Route>
          <Route path="/company/:companyId/bot/:botId/objections">
            <CompanyBotObjections />
          </Route>
          <Route path="/company/:companyId/bot/:botId/pre-modal">
            <CompanyBotPreModal />
          </Route>
          <Route path="/company/:companyId/bot/:botId/service-questions">
            <CompanyBotServiceQuestions />
          </Route>
          <Route path="/company/:companyId/bot/:botId">
            <CompanyBotDetail />
          </Route>
          <Route path="/company/:companyId/programs">
            <CompanyProgramPage />
          </Route>
          <Route path="/company/:companyId/data-services">
            <CompanyDataServices />
          </Route>
          <Route path="/company/:companyId/offers">
            <CompanyOffersPage />
          </Route>
          <Route path="/company/:companyId/offer/new">
            <CompanyOfferNew />
          </Route>
          <Route path="/company/:companyId/offer/:offerId">
            <CompanyOfferDetail />
          </Route>
          <Route path="/company/:companyId/price-tables">
            <CompanyPriceTablePage />
          </Route>
          <Route path="/company/:companyId/bots">
            <CompanyBotsPage />
          </Route>
          <Route path="/company/:companyId/branding">
            <CompanyBranding />
          </Route>
          <Route path="/company/:companyId/real-green">
            <RealGreenIntegration />
          </Route>
          <Route path="/company/:companyId/pest-pac">
            <PestPacPage />
          </Route>
          <Route path="/company/:companyId/servman">
            <div>Servman</div>
          </Route>
          <Route path="/company/:companyId/google-sheets">
            <GoogleSheetIntegration />
          </Route>
          <Route path="/company/:companyId/lawnbot-dashboard">
            <LawnbotDashboardIntegration />
          </Route>
          <Route path="/company/:companyId">
            <CompanyDetail />
          </Route>
          <Route path="/companies">
            <Container maxWidth="lg" className={classes.container}>
              <CompanyPage />
            </Container>
          </Route>
          <Route path="/">
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                  <Paper className={fixedHeightPaper}>
                    <Chart />
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper className={fixedHeightPaper}>
                    <Deposits />
                  </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <CompanyListTable classes={classes} companies={companies} />
                  </Paper>
                </Grid>
              </Grid>
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </Route>
        </Switch>
      </main>
    </div>
  ) : null;
}
