import React, { useState } from "react";
import { MenuItem, Button, FormLabel, Checkbox } from "@material-ui/core";
import states from "./us-state";
import { PropTypes } from "prop-types";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
  FormControlLabel
} from "react-material-ui-form-validator";
import { currencies } from "../../currencies/currencies";

const VatLabel = () => (
  <div>
    <label htmlFor="Vat">Vat Tax</label>
    <TextValidator type="text" />
  </div>
);

const autoPayPriceMethods = [
  {
    value: "first-application-payment",
    name: "First Application Payment"
  },
  {
    value: "monthly-payment",
    name: "Monthly Payment"
  }
];

const crmSolutions = [
  {
    value: "none",
    name: "None"
  },
  {
    value: "real-green",
    name: "Real Green"
  },
  {
    value: "pest-pac",
    name: "Pest Pac"
  },
  {
    value: "servman",
    name: "Servman"
  },
  {
    value: "google-sheets",
    name: "Google Sheets"
  }
];

const paymentOptions = [
  {
    value: "stripe",
    name: "Stripe"
  },
  {
    value: "real-green-payment-processing",
    name: "Real Green Payment Processing"
  },
  {
    value: "lawnbot-credit-card-vault",
    name: "Lawnbot Credit Card Valut"
  }
];

export function CompanyInfoForm(props) {
  const { action = "Save" } = props;

  const buttonStyle = {
    marginTop: 20
  };

  const submitForm = (e) => {
    props.submit(company);
  };
  const updateForm = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const payload = {};
    payload[key] = val;
    setCompany({ ...company, ...payload });
  };

  const [company, setCompany] = useState(
    props.company || {
      companyName: "",
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      customerAddress: "",
      customerCity: "",
      customerState: "MI",
      customerZip: "",
      serviceTax: 0.0,
      currency: "",
      maxQuotableSize: 40000,
      minQuotableSize: 1000,
      autoPayAvailable: true,
      autoPayMonthlyPricePeriod: 12,
      prePayAvailable: true,
      termsOfService: `Nothing will be charged today.  Services will be billed as the work is performed.
      Services renew annually. For a change in service please contact us at xxx-xxx-xxxx.`,
      serviceAgreementContent: "",
      noServiceAgreementContent: "",
      serviceAgreementTellMeMore: "",
      emergencyTellMeMore: "",
      gettingStartedText: ""
    }
  );

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={submitForm}>
        <TextValidator
          id="companyName"
          label="Company Name"
          value={company.companyName}
          style={{ margin: 8, width: 300 }}
          placeholder="Awesome LawnCare Company"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />

        <div>
          <TextValidator
            id="customerName"
            label="Customer Name"
            value={company.customerName}
            style={{ margin: 8, width: 300 }}
            placeholder="John Doe"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <div>
          <TextValidator
            id="customerEmail"
            value={company.customerEmail}
            label="Customer Email"
            style={{ margin: 8, width: 300 }}
            placeholder="xxxxx@gmail.com"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />

          <TextValidator
            id="customerPhone"
            value={company.customerPhone}
            label="Phone Number"
            style={{ margin: 8, width: 300 }}
            placeholder="xxx-xxx-xxxx"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <div>
          <TextValidator
            id="customerAddress"
            label="Address"
            value={company.customerAddress}
            style={{ margin: 8, width: 300 }}
            placeholder="xxx-xxx-xxxx"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <div>
          <TextValidator
            id="customerCity"
            label="City"
            value={company.customerCity}
            style={{ margin: 8, width: 300 }}
            placeholder="Boise"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />

          <SelectValidator
            labelid="customerState"
            id="state-select"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={company.customerState}
            onChange={(e) => {
              setCompany({ ...company, customerState: e.target.value });
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {states.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </SelectValidator>
          <TextValidator
            id="customerZip"
            label="Zip Code"
            value={company.customerZip}
            style={{ margin: 8, width: 100 }}
            placeholder="xxxxx"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <div>
          <TextValidator
            id="serviceTax"
            label={
              company.currency === "British Pound Sterling"
                ? "Vat/Service Tax"
                : "Service Tax"
            }
            value={company.serviceTax}
            style={{ margin: 8, width: 300 }}
            placeholder="0.0"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />

          <SelectValidator
            label="currency"
            labelid="customerCurrency"
            id="currency-select"
            style={{
              margin: 8,
              width: 100
            }}
            value={company.currency}
            onChange={(e) => {
              setCompany({ ...company, currency: e.target.value });
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {currencies.map(({ name }, index) => {
              const key =
                "currency-dropdown-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={name}>
                  {name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>

        <div>
          <TextValidator
            id="maxQuotableSize"
            label="Max Quotable Lot Size (sq ft)"
            value={company.maxQuotableSize}
            style={{ margin: 8, width: 300 }}
            placeholder="40000"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <TextValidator
            id="minQuotableSize"
            label="Min Quotable Lot Size (sq ft)"
            value={company.minQuotableSize}
            style={{ margin: 8, width: 300 }}
            placeholder="1000"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>
        <div>
          <SelectValidator
            labelid="crmSolution"
            id="crmSolution"
            label="CRM Solution"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={company.crmSolution || "none"}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                crmSolution: e.target.value
              });
              setCompany(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {crmSolutions.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>

        <div>
          <SelectValidator
            labelid="paymentType"
            id="paymentType"
            label="Credit Card Processing"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={company.paymentType || "stripe"}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                paymentType: e.target.value
              });
              setCompany(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {paymentOptions.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel>Bypass Payment</FormLabel>
          <Checkbox
            id="bypassPayment"
            label="ByPass Credit Card Payment"
            checked={company.bypassPayment}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                bypassPayment: e.target.checked
              });
              setCompany(payload);
            }}
          />
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Auto-Pay Available</FormLabel>
          <Checkbox
            id="autoPayAvailable"
            label="Auto-Pay Available"
            checked={company.autoPayAvailable}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                autoPayAvailable: e.target.checked
              });
              setCompany(payload);
            }}
          />
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Pre-Pay Available</FormLabel>
          <Checkbox
            id="prePayAvailable"
            label="Pre-Pay Available"
            checked={
              typeof company.prePayAvailable === "undefined" ||
              company.prePayAvailable === null
                ? true
                : company.prePayAvailable
            }
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                prePayAvailable: e.target.checked
              });
              setCompany(payload);
            }}
          />
        </div>
        <div>
          <TextValidator
            id="autoPayAlternativeLabel"
            label="Auto-Pay Label"
            value={company.autoPayAlternativeLabel || "Auto-Pay"}
            style={{ margin: 8, width: 300 }}
            placeholder="1000"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <SelectValidator
            labelid="autoPayPriceMethod"
            id="autoPayPriceMethod"
            label="Auto-Pay Price Method"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={company.autoPayPriceMethod || "first-application-payment"}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                autoPayPriceMethod: e.target.value
              });
              setCompany(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {autoPayPriceMethods.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Tax Override</FormLabel>
          <Checkbox
            id="taxOverride"
            disabled={company.autoPayPriceMethod !== "monthly-payment"}
            label="Tax Override"
            checked={
              company.taxOverride || company.taxOverride === null ? true : false
            }
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                taxOverride: e.target.checked
              });
              setCompany(payload);
            }}
          />
        </div>
        <div>
          <SelectValidator
            labelid="autoPayMonthlyPricePeriod"
            id="autoPayMonthlyPricePeriod"
            label="Auto-Pay Price Period"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={company.autoPayMonthlyPricePeriod || 12}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                autoPayMonthlyPricePeriod: e.target.value
              });
              setCompany(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i}>
                  {i} Months
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div>
          <TextValidator
            id="approved"
            label="Approved (Onboarding Complete)"
            value={
              company.approved || company.approved === false
                ? company.approved.toString()[0].toUpperCase() +
                  company.approved.toString().substring(1)
                : "True"
            }
            style={{ margin: 8, marginTop: 15, width: 300 }}
            placeholder="1000"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
        </div>
        <div>
          <TextValidator
            id="approvedBy"
            label="Approved By"
            value={company.approvedBy}
            style={{ margin: 8, width: 300 }}
            placeholder="n/a"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
          <TextValidator
            id="approvedOn"
            label="Approval Date"
            value={company.approvedOn}
            style={{ margin: 8, width: 300 }}
            placeholder="n/a"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
        </div>

        <div>
          <TextValidator
            id="gettingStartedText"
            label="Getting Started Text"
            value={company.gettingStartedText}
            style={{ margin: 8, width: 300 }}
            placeholder="Let's get started"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            disabled={false}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <br />
          <p>Terms of Service</p>
          <br />
          <br />
          <textarea
            id="zipCodes"
            style={{ width: 500 }}
            rows={20}
            value={company.termsOfService}
            onChange={(e) => {
              const payload = Object.assign({}, company, {
                termsOfService: e.target.value
              });
              setCompany(payload);
            }}
          ></textarea>
        </div>

        {/* Has Service Agreement Content */}
        {company.crmSolution === "servman" && (
          <div>
            <br />
            <p>Has Service Agreement Content</p>
            <br />
            <br />
            <textarea
              id="serviceAgreementContent"
              style={{ width: 500 }}
              rows={10}
              value={company.serviceAgreementContent}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  serviceAgreementContent: e.target.value
                });
                setCompany(payload);
              }}
            ></textarea>
          </div>
        )}

        {/* Has NO Service Agreement Content */}
        {company.crmSolution === "servman" && (
          <div>
            <br />
            <p>Has No Service Agreement Content</p>
            <br />
            <br />
            <textarea
              id="noServiceAgreementContent"
              style={{ width: 500 }}
              rows={10}
              value={company.noServiceAgreementContent}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  noServiceAgreementContent: e.target.value
                });
                setCompany(payload);
              }}
            ></textarea>
          </div>
        )}

        {/* serviceAgreementTellMeMore Tell Me More */}
        {company.crmSolution === "servman" && (
          <div>
            <br />
            <p>Service Agreement &apos;Tell Me More&apos; Content</p>
            <br />
            <br />
            <textarea
              id="serviceAgreementTellMeMore"
              style={{ width: 500 }}
              rows={10}
              value={company.serviceAgreementTellMeMore}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  serviceAgreementTellMeMore: e.target.value
                });
                setCompany(payload);
              }}
            ></textarea>
          </div>
        )}

        {/* emergencyTellMeMore Tell Me More */}
        {company.crmSolution === "servman" && (
          <div>
            <br />
            <p>Emergency &apos;Tell Me More&apos; Content</p>
            <br />
            <br />
            <textarea
              id="emergencyTellMeMore"
              style={{ width: 500 }}
              rows={10}
              value={company.emergencyTellMeMore}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  emergencyTellMeMore: e.target.value
                });
                setCompany(payload);
              }}
            ></textarea>
          </div>
        )}

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          {action} Customer
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

CompanyInfoForm.propTypes = {
  company: PropTypes.object,
  submit: PropTypes.func
};
