/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      createdAt
      updatedAt
      branding {
        id
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          id
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          id
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          id
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyBranding = /* GraphQL */ `
  query GetCompanyBranding($id: ID!) {
    getCompanyBranding(id: $id) {
      id
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyBrandings = /* GraphQL */ `
  query ListCompanyBrandings(
    $filter: ModelCompanyBrandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyBrandings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBot = /* GraphQL */ `
  query GetBot($id: ID!) {
    getBot(id: $id) {
      id
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listBots = /* GraphQL */ `
  query ListBots(
    $filter: ModelBotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      sectionName
      entryId
      nextSectionId
      createdAt
      updatedAt
      conditionals {
        items {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      bot {
        version
        id
        botCompanyId
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        hideArielAndStreetView
        customSchedulingVerbiage
        forceKey
        createdAt
        updatedAt
        sections {
          nextToken
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProbingQuestions = /* GraphQL */ `
  query GetProbingQuestions($id: ID!) {
    getProbingQuestions(id: $id) {
      id
      probingQuestionsProgramId
      sectionName
      entryId
      nextSectionId
      steps {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      conditionals {
        id
        matches
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listProbingQuestionss = /* GraphQL */ `
  query ListProbingQuestionss(
    $filter: ModelProbingQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProbingQuestionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        probingQuestionsProgramId
        sectionName
        entryId
        nextSectionId
        steps {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        conditionals {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConditional = /* GraphQL */ `
  query GetConditional($id: ID!) {
    getConditional(id: $id) {
      id
      matches
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listConditionals = /* GraphQL */ `
  query ListConditionals(
    $filter: ModelConditionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditionals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        matches
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStep = /* GraphQL */ `
  query GetStep($id: ID!) {
    getStep(id: $id) {
      id
      stepName
      optionType
      message
      end
      asMessage
      waitAction
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      options {
        items {
          id
          value
          label
          iconClass
          color
          image
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listSteps = /* GraphQL */ `
  query ListSteps(
    $filter: ModelStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      value
      label
      iconClass
      color
      image
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        label
        iconClass
        color
        image
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComponent = /* GraphQL */ `
  query GetComponent($id: ID!) {
    getComponent(id: $id) {
      id
      componentName
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      props {
        items {
          id
          key
          value
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProp = /* GraphQL */ `
  query GetProp($id: ID!) {
    getProp(id: $id) {
      id
      key
      value
      createdAt
      updatedAt
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listProps = /* GraphQL */ `
  query ListProps(
    $filter: ModelPropFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
        createdAt
        updatedAt
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          id
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        id
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        roundNumber
        priceTableId
        cutOffDate
        createdAt
        updatedAt
        program {
          id
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceTable = /* GraphQL */ `
  query GetPriceTable($id: ID!) {
    getPriceTable(id: $id) {
      id
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listPriceTables = /* GraphQL */ `
  query ListPriceTables(
    $filter: ModelPriceTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceTableRows = /* GraphQL */ `
  query GetPriceTableRows($id: ID!) {
    getPriceTableRows(id: $id) {
      id
      priceTableRowsPriceTableId
      start
      end
      price
      units
      createdAt
      updatedAt
      priceTable {
        version
        priceTableCompanyId
        id
        isDeleted
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        rows {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listPriceTableRowss = /* GraphQL */ `
  query ListPriceTableRowss(
    $filter: ModelPriceTableRowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceTableRowss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        priceTableRowsPriceTableId
        start
        end
        price
        units
        createdAt
        updatedAt
        priceTable {
          version
          priceTableCompanyId
          id
          isDeleted
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceType = /* GraphQL */ `
  query GetServiceType($id: ID!) {
    getServiceType(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServiceTypes = /* GraphQL */ `
  query ListServiceTypes(
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDataServices = /* GraphQL */ `
  query GetCompanyDataServices($id: ID!) {
    getCompanyDataServices(id: $id) {
      id
      stripeTestSecret
      stripeSecret
      realGreenAPIKey
      serviceAssistantId
      zillowAPIKey
      airTableBase
      companyDataServicesCompanyId
      airTableTableName
      googleSheetsClientEmail
      googleSheetsPrivateKey
      servmanApiKey
      servmanApiAuth
      servmanApiUrl
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyDataServicess = /* GraphQL */ `
  query ListCompanyDataServicess(
    $filter: ModelCompanyDataServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDataServicess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeTestSecret
        stripeSecret
        realGreenAPIKey
        serviceAssistantId
        zillowAPIKey
        airTableBase
        companyDataServicesCompanyId
        airTableTableName
        googleSheetsClientEmail
        googleSheetsPrivateKey
        servmanApiKey
        servmanApiAuth
        servmanApiUrl
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      name
      startDate
      endDate
      programs
      offerCompanyId
      active
      hide
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        programs
        offerCompanyId
        active
        hide
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyVersioned = /* GraphQL */ `
  query GetCompanyVersioned($id: ID!, $version: String!) {
    getCompanyVersioned(id: $id, version: $version) {
      version
      id
      approved
      approvedBy
      approvedOn
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      companyBrandingId
      companyDataServicesId
      currency
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      taxOverride
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      pestPacTenantId
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      onboardingJson
      serviceAgreementContent
      noServiceAgreementContent
      serviceAgreementTellMeMore
      emergencyTellMeMore
      gettingStartedText
      crmMeasurementUnit
      createdAt
      updatedAt
      dataServices {
        id
        stripeTestSecret
        stripeSecret
        realGreenAPIKey
        serviceAssistantId
        zillowAPIKey
        airTableBase
        companyDataServicesCompanyId
        airTableTableName
        googleSheetsClientEmail
        googleSheetsPrivateKey
        servmanApiKey
        servmanApiAuth
        servmanApiUrl
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      offers {
        items {
          id
          name
          startDate
          endDate
          programs
          offerCompanyId
          active
          hide
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      branding {
        version
        id
        companyBrandingCompanyId
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          version
          priceTableCompanyId
          id
          isDeleted
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyVersioneds = /* GraphQL */ `
  query ListCompanyVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelCompanyVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyBrandingVersioned = /* GraphQL */ `
  query GetCompanyBrandingVersioned($id: ID!, $version: String!) {
    getCompanyBrandingVersioned(id: $id, version: $version) {
      version
      id
      companyBrandingCompanyId
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCompanyBrandingVersioneds = /* GraphQL */ `
  query ListCompanyBrandingVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelCompanyBrandingVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyBrandingVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        id
        companyBrandingCompanyId
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBotVersioned = /* GraphQL */ `
  query GetBotVersioned($id: ID!, $version: String!) {
    getBotVersioned(id: $id, version: $version) {
      version
      id
      botCompanyId
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      hideArielAndStreetView
      customSchedulingVerbiage
      forceKey
      createdAt
      updatedAt
      sections {
        items {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listBotVersioneds = /* GraphQL */ `
  query ListBotVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelBotVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBotVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        id
        botCompanyId
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        hideArielAndStreetView
        customSchedulingVerbiage
        forceKey
        createdAt
        updatedAt
        sections {
          nextToken
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProgramVersioned = /* GraphQL */ `
  query GetProgramVersioned($id: ID!, $version: String!) {
    getProgramVersioned(id: $id, version: $version) {
      version
      id
      programCompanyId
      isDeleted
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      hideDiscountText
      programProbingQuestionsId
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      recurringPricingTable
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      probingQuestions {
        id
        probingQuestionsProgramId
        sectionName
        entryId
        nextSectionId
        steps {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        conditionals {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        crmMeasurementUnit
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          version
          id
          isDeleted
          serviceProgramId
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listProgramVersioneds = /* GraphQL */ `
  query ListProgramVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelProgramVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProgramVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceVersioned = /* GraphQL */ `
  query GetServiceVersioned($id: ID!, $version: String!) {
    getServiceVersioned(id: $id, version: $version) {
      version
      id
      isDeleted
      serviceProgramId
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          crmMeasurementUnit
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listServiceVersioneds = /* GraphQL */ `
  query ListServiceVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelServiceVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        id
        isDeleted
        serviceProgramId
        serviceName
        roundNumber
        priceTableId
        cutOffDate
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceTableVersioned = /* GraphQL */ `
  query GetPriceTableVersioned($id: ID!, $version: String!) {
    getPriceTableVersioned(id: $id, version: $version) {
      version
      priceTableCompanyId
      id
      isDeleted
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      rows {
        items {
          id
          priceTableRowsPriceTableId
          start
          end
          price
          units
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPriceTableVersioneds = /* GraphQL */ `
  query ListPriceTableVersioneds(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelPriceTableVersionedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPriceTableVersioneds(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        priceTableCompanyId
        id
        isDeleted
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        rows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
