import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";

const custom = [
  {
    name: "bridgeApi",
    endpoint: "https://ymiosb7s1l.execute-api.us-east-1.amazonaws.com/dev",
    // endpoint: "http://localhost:3006",
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      };
    }
  }
];
awsExports.aws_cloud_logic_custom = custom;
Amplify.configure(awsExports);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
